import React, { useCallback, useState } from "react";
import { Button } from "../../Button";
import "./styles.css";
import { useFormDownoladCatalog } from "./useFormDownoladCatalog";
import cn from "classnames";

const ShopEquipmentDownloadCatalogContent = ({
  className,
  title,
  text,
  categories,
  emailFieldPlaceholder,
  buttonLabel,
  catalog,
}) => {
  const {
    formState: { isDisable, lang },
    formRef: { downoladLink },
    formErrors: { errorName, errorPhoneNumber, errorEmail },
    formImput: { name, phoneNumber, email },
    formHandle: {
      handleFocusPhoneNumber,
      handleBlurPhoneNumber,
      handleChangeName,
      handleChangePhoneNumber,
      handleChangeEmail,
      handleDownloadCatalog,
    },
  } = useFormDownoladCatalog(catalog.publicURL);

  const InputNameStyle = cn({
    ShopEquipmentDownloadCatalogContent__input: true,

    input__nameError: errorName,
  });

  const InputPhoneNumberStyle = cn({
    ShopEquipmentDownloadCatalogContent__input: true,

    input__phoneNumberError: errorPhoneNumber,
  });

  const InputEmailStyle = cn({
    ShopEquipmentDownloadCatalogContent__input: true,

    input__emailError: errorEmail,
  });
  return (
    <div className={className}>
      <h2 className="Font_header sm:Font_smallHeader">{title}</h2>
      <p className="[ ShopEquipmentDownloadCatalogContent__text ] [ Font_mediumText ]">{text}</p>
      <ul className="[ ShopEquipmentDownloadCatalogContent__categories ] [ Font_mediumText Color_darkGrey ]">
        {categories.map(category => (
          <li key={category}>• {category}</li>
        ))}
      </ul>

      <div className="ShopEquipmentDownloadCatalogContent__form">
        <div className="ShopEquipmentDownloadCatalogContent__inputWrapper InputWrapper__name">
          <input
            className={`[ ${InputNameStyle} ] [ Font_mediumText ]`}
            type="text"
            placeholder={`${lang === "ru" ? "Ваше имя" : "Your name"}`}
            value={name}
            required
            onChange={handleChangeName}
          />
          {errorName && (
            <div className="[ Font_smallText Color_red ]">{lang === "ru" ? "Введите ваше имя" : "Enter your name"}</div>
          )}
        </div>

        <div className="ShopEquipmentDownloadCatalogContent__inputWrapper InputWrapper__phoneNumber">
          <input
            className={`[ ${InputPhoneNumberStyle} ] [ Font_mediumText ]`}
            type="text"
            placeholder={`${lang === "ru" ? "Телефон" : "Your phone number"}`}
            value={phoneNumber}
            required
            onFocus={handleFocusPhoneNumber}
            onBlur={handleBlurPhoneNumber}
            onChange={handleChangePhoneNumber}
          />

          {errorPhoneNumber && (
            <div className="[ Font_smallText Color_red ]">
              {lang === "ru" ? "Проверьте, пожалуйста, номер телефона" : "Please check your phone number"}
            </div>
          )}
        </div>

        <div className="ShopEquipmentDownloadCatalogContent__inputWrapper InputWrapper__email">
          <input
            className={`[ ${InputEmailStyle} ] [ Font_mediumText ]`}
            type="text"
            placeholder={emailFieldPlaceholder}
            value={email}
            onChange={handleChangeEmail}
            required
          />
          {errorEmail && (
            <div className="[ Font_smallText Color_red ]">
              {lang === "ru" ? "Проверьте, пожалуйста, email адрес" : "Please check your email address"}
            </div>
          )}
        </div>
      </div>
      {/* <a
        className="ShopEquipmentDownloadCatalogContent__catalog"
        href={catalog.publicURL}
        ref={downoladLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleDownloadCatalog}
      >
        
      </a> */}
      <Button
        onClick={handleDownloadCatalog}
        className="ShopEquipmentDownloadCatalogContent__button"
        // disabled={isDisable}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

export { ShopEquipmentDownloadCatalogContent };
