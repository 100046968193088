import { graphql } from "gatsby";
import React from "react";
import { ShopEquipmentHeroBackground } from "./ShopEquipmentHeroBackground";
import { ShopEquipmentHeroContent } from "./ShopEquipmentHeroContent";
import "./styles.css";

const ShopEquipmentHero = ({ background, alt, title, text, solutions }) => {
  return (
    <div className="[ ShopEquipmentHero ] [ Content sm:Content_medium ]">
      <ShopEquipmentHeroContent
        className="ShopEquipmentHero__content"
        title={title}
        text={text}
        solutions={solutions}
      />

      <ShopEquipmentHeroBackground className="ShopEquipmentHero__background" background={background} alt={alt} />
    </div>
  );
};

ShopEquipmentHero.getLivePreviewData = ({ data, getAsset }) => ({
  shopEquipmentHero: {
    ...data.shopEquipmentHero,
    background: getAsset(data.shopEquipmentHero.background).url,
  },
});

const shopEquipmentHeroQuery = graphql`
  fragment ShopEquipmentHeroFragment on MarkdownRemarkFrontmatter {
    shopEquipmentHero {
      background {
        childImageSharp {
          fluid(maxWidth: 1260) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
      title
      text
      solutions {
        title
        text
      }
    }
  }
`;

export { ShopEquipmentHero, shopEquipmentHeroQuery };
