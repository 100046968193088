import { graphql } from "gatsby";
import React from "react";
import { Button } from "../Button";
import background from "./background.svg";
import "./styles.css";

const ShopEquipmentUniversal = ({ image, alt, title, text, buttonLabel, onOpenContactUsModal }) => {
  return (
    <div className="ShopEquipmentUniversal">
      <img src={background} className="ShopEquipmentUniversal__illustration" alt="" />

      <div className="[ ShopEquipmentUniversal__content ] [ Content  sm:Content_medium ]">
        <img className="ShopEquipmentUniversal__image" src={image.childImageSharp.fluid.src} alt={alt} />
        <div className="ShopEquipmentUniversal__info">
          <h1 className="ShopEquipmentUniversal__title  [ Font_header sm:Font_smallHeader  Color_black ]">{title}</h1>
          <div className="ShopEquipmentUniversal__description [ Font_regularText Color_darkGrey ]">{text}</div>
          <Button className="ShopEquipmentUniversal__order" onClick={onOpenContactUsModal}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

ShopEquipmentUniversal.getLivePreviewData = ({ data, getAsset }) => ({
  shopEquipmentUniversal: {
    ...data.shopEquipmentUniversal,
    image: getAsset(data.shopEquipmentUniversal).url,
  },
});

const shopEquipmentUniversalQuery = graphql`
  fragment ShopEquipmentUniversalFragment on MarkdownRemarkFrontmatter {
    shopEquipmentUniversal {
      image {
        childImageSharp {
          fluid(maxWidth: 678) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
      title
      text
      buttonLabel
    }
  }
`;

export { ShopEquipmentUniversal, shopEquipmentUniversalQuery };
