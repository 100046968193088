import { graphql } from "gatsby";
import React from "react";
import { BlackCard } from "../BlackCard/BlackCard";
import "./styles.css";

const ShopEquipmentNewShop = ({ title, text, buttonLabel, image, onOpenContactUsModal }) => {
  return (
    <div className="[ ShopEquipmentNewShop ] [ Content ]">
      <BlackCard
        title={title}
        text={text}
        buttonLabel={buttonLabel}
        image={image}
        onOpenContactUsModal={onOpenContactUsModal}
      />
    </div>
  );
};

ShopEquipmentNewShop.getLivePreviewData = ({ data, getAsset }) => ({
  shopEquipmentNewShop: {
    ...data.shopEquipmentNewShop,
  },
});

const shopEquipmentNewShopQuery = graphql`
  fragment ShopEquipmentNewShopFragment on MarkdownRemarkFrontmatter {
    shopEquipmentNewShop {
      title
      text
      buttonLabel
      image {
        publicURL
      }
    }
  }
`;

export { ShopEquipmentNewShop, shopEquipmentNewShopQuery };
