import cn from "classnames";
import React from "react";
import { Image } from "../../Image";
import "./styles.css";

const ShopEquipmentHeroBackground = ({ className, background, alt }) => {
  return (
    <div className={cn(className, "ShopEquipmentHeroBackground")}>
      <Image className="ShopEquipmentHeroBackground__image" image={background} alt={alt} />
    </div>
  );
};

export { ShopEquipmentHeroBackground };
