import { graphql } from "gatsby";
import React, { useCallback, useState } from "react";
import Helmet from "react-helmet";
import { ContactUsModal } from "../components/ContactUsModal";
import { Layout } from "../components/Layout";
import { ShopEquipmentDownloadCatalog } from "../components/ShopEquipmentDownloadCatalog/ShopEquipmentDownloadCatalog";
import { ShopEquipmentHero } from "../components/ShopEquipmentHero/ShopEquipmentHero";
import { ShopEquipmentNewShop } from "../components/ShopEquipmentNewShop/ShopEquipmentNewShop";
import { ShopEquipmentPosStands } from "../components/ShopEquipmentPosStands/ShopEquipmentPosStands";
import { ShopEquipmentPOSStandsProjects } from "../components/ShopEquipmentPOSStandsProjects";
import { ShopEquipmentShoppingRoom } from "../components/ShopEquipmentShoppingRoom";
import { ShopEquipmentShoppingRoomProjects } from "../components/ShopEquipmentShoppingRoomProjects";
import { ShopEquipmentUniversal } from "../components/ShopEquipmentUniversal";
import { ShopEquipmentUniversalProjects } from "../components/ShopEquipmentUniversalProjects";

const ShopEquipmentPageTemplate = ({ layout, ...props }) => {
  const [isOpenContactUsModal, setIsOpenContactUsModal] = useState();
  const handleOpenContactUsModal = useCallback(() => setIsOpenContactUsModal(true), []);
  const handleCloseContactUsModal = useCallback(() => setIsOpenContactUsModal(false), []);

  return (
    <Layout {...layout} onOpenContactUsModal={handleOpenContactUsModal} lang={props.layoutId}>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <div style={{ boxShadow: "0px 0px 64px rgba(0, 0, 0, 0.16)", position: "relative", zIndex: 3 }}>
        <div style={{ boxShadow: "0px 0px 64px rgba(0, 0, 0, 0.16)", position: "relative", zIndex: 2 }}>
          <div style={{ boxShadow: "0px 0px 64px rgba(0, 0, 0, 0.16)", position: "relative", zIndex: 1 }}>
            <ShopEquipmentHero {...props.shopEquipmentHero} />
          </div>
          <ShopEquipmentUniversal {...props.shopEquipmentUniversal} onOpenContactUsModal={handleOpenContactUsModal} />
          <ShopEquipmentDownloadCatalog {...props.shopEquipmentDownloadCatalog} />
          <ShopEquipmentUniversalProjects {...props.shopEquipmentUniversalProjects} />
        </div>
        <ShopEquipmentShoppingRoom {...props.shopEquipmentShoppingRoom} />
        <ShopEquipmentShoppingRoomProjects {...props.shopEquipmentShoppingRoomProjects} />
        <ShopEquipmentNewShop {...props.shopEquipmentNewShop} onOpenContactUsModal={handleOpenContactUsModal} />
      </div>
      <ShopEquipmentPosStands {...props.shopEquipmentPosStands} onOpenContactUsModal={handleOpenContactUsModal} />
      <ShopEquipmentPOSStandsProjects {...props.shopEquipmentPOSStandsProjects} />

      <ContactUsModal isOpen={isOpenContactUsModal} onClose={handleCloseContactUsModal} {...layout.contactUsModal} />
    </Layout>
  );
};

ShopEquipmentPageTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...ShopEquipmentHero.getLivePreviewData(props),
  ...ShopEquipmentUniversal.getLivePreviewData(props),
  ...ShopEquipmentDownloadCatalog.getLivePreviewData(props),
  ...ShopEquipmentUniversalProjects.getLivePreviewData(props),
  ...ShopEquipmentShoppingRoom.getLivePreviewData(props),
  ...ShopEquipmentShoppingRoomProjects.getLivePreviewData(props),
  ...ShopEquipmentNewShop.getLivePreviewData(props),
  ...ShopEquipmentPosStands.getLivePreviewData(props),
  ...ShopEquipmentPOSStandsProjects.getLivePreviewData(props),
});

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return <ShopEquipmentPageTemplate layout={data.layout.frontmatter} {...frontmatter} />;
};

const pageQuery = graphql`
  query ShopEquipmentPageTemplate($id: String!, $layoutId: String!) {
    layout: markdownRemark(frontmatter: { id: { eq: $layoutId } }) {
      frontmatter {
        ...LayoutFragment
        ...ContactUsModalFragment
      }
    }

    markdownRemark(id: { eq: $id }) {
      frontmatter {
        layoutId
        seo {
          title
          description
          keywords
        }

        ...ShopEquipmentHeroFragment
        ...ShopEquipmentUniversalFragment
        ...ShopEquipmentDownloadCatalogFragment
        ...ShopEquipmentUniversalProjectsFragment
        ...ShopEquipmentShoppingRoomFragment
        ...ShopEquipmentShoppingRoomProjectsFragment
        ...ShopEquipmentNewShopFragment
        ...ShopEquipmentPosStandsFragment
        ...ShopEquipmentPOSStandsProjectsFragment
      }
    }
  }
`;

export default IndexPage;

export { pageQuery, ShopEquipmentPageTemplate };
