import cn from "classnames";
import React from "react";
import "./styles.css";

const ShopEquipmentHeroContent = ({ className, title, text, solutions }) => {
  return (
    <div className={cn(className, "ShopEquipmentHeroContent")}>
      <h1 className="Font_superHeader sm:Font_smallHeader">{title}</h1>
      <p className="[ ShopEquipmentHeroContent__subtitle ] [ Font_bigText sm:Font_regularText Color_darkGrey ]">
        {text}
      </p>

      <div className="ShopEquipmentHeroContent__solutions">
        {solutions.map(({ title, text }) => (
          <div key={title} className="ShopEquipmentHeroContent__solution">
            <h2 className="Font_mediumTextBold">{title}</h2>
            <p className="Font_regularText Color_darkGrey">{text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export { ShopEquipmentHeroContent };
