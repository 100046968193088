import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import { ShopEquipmentDownloadCatalogContent } from "./ShopEquipmentDownloadCatalogContent";
import "./styles.css";

const ShopEquipmentDownloadCatalog = ({
  image,
  alt,
  title,
  text,
  categories,
  emailFieldPlaceholder,
  buttonLabel,
  catalog,
}) => {
  return (
    <div className="ShopEquipmentDownloadCatalog" id="download-catalog">
      <div className="[ ShopEquipmentDownloadCatalog__container ] [ Content sm:Content_medium ]">
        <ShopEquipmentDownloadCatalogContent
          className="ShopEquipmentDownloadCatalog__content"
          title={title}
          text={text}
          categories={categories}
          emailFieldPlaceholder={emailFieldPlaceholder}
          buttonLabel={buttonLabel}
          catalog={catalog}
        />

        <Image className="ShopEquipmentDownloadCatalog__image" image={image} alt={alt} />
      </div>
    </div>
  );
};

ShopEquipmentDownloadCatalog.getLivePreviewData = ({ data, getAsset }) => ({
  shopEquipmentDownloadCatalog: {
    ...data.shopEquipmentDownloadCatalog,
    image: getAsset(data.shopEquipmentDownloadCatalog.image).url,
  },
});

const shopEquipmentDownloadCatalogQuery = graphql`
  fragment ShopEquipmentDownloadCatalogFragment on MarkdownRemarkFrontmatter {
    shopEquipmentDownloadCatalog {
      image {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
      title
      text
      categories
      emailFieldPlaceholder
      buttonLabel

      catalog {
        publicURL
      }
    }
  }
`;

export { ShopEquipmentDownloadCatalog, shopEquipmentDownloadCatalogQuery };
