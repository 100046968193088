import { graphql } from "gatsby";
import React from "react";
import { Button } from "../Button";
import { Image } from "../Image";
import background from "./background.svg";
import "./styles.css";

const ShopEquipmentPosStands = ({ title, text, buttonLabel, image, alt, onOpenContactUsModal }) => {
  return (
    <div className="ShopEquipmentPosStands">
      <img className="ShopEquipmentPosStands__illustration" src={background} alt="" />

      <div className="[ ShopEquipmentPosStands__container ] [ Content sm:Content_medium ]">
        <div className="ShopEquipmentPosStands__content">
          <h2 className="Font_header sm:Font_smallHeader">{title}</h2>
          <p className="[ ShopEquipmentPosStands__text ] [ Font_regularText Color_darkGrey ]">{text}</p>
          <Button className="[ ShopEquipmentPosStands__button ]" onClick={onOpenContactUsModal}>
            {buttonLabel}
          </Button>
        </div>

        <div className="ShopEquipmentPosStands__imageContainer">
          <Image className="ShopEquipmentPosStands__image" image={image} alt={alt} />
        </div>
      </div>
    </div>
  );
};

ShopEquipmentPosStands.getLivePreviewData = ({ data, getAsset }) => ({
  shopEquipmentPosStands: {
    ...data.shopEquipmentPosStands,
  },
});

const shopEquipmentPosStandsQuery = graphql`
  fragment ShopEquipmentPosStandsFragment on MarkdownRemarkFrontmatter {
    shopEquipmentPosStands {
      title
      text
      buttonLabel
      image {
        childImageSharp {
          fluid(maxWidth: 632) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
    }
  }
`;

export { ShopEquipmentPosStands, shopEquipmentPosStandsQuery };
