import { graphql } from "gatsby";
import React from "react";
import { CompletedProjects } from "../CompletedProjects/CompletedProjects";
import "./styles.css";

const ShopEquipmentUniversalProjects = ({ title, examples }) => {
  return <CompletedProjects title={title} examples={examples} />;
};

ShopEquipmentUniversalProjects.getLivePreviewData = ({ data, getAsset }) => ({
  shopEquipmentUniversalProjects: {
    ...data.shopEquipmentUniversalProjects,
    examples: data.shopEquipmentUniversalProjects.examples.map(example => ({
      ...example,
      photo: getAsset(example.photo).url,
    })),
  },
});

const shopEquipmentUniversalProjectsQuery = graphql`
  fragment ShopEquipmentUniversalProjectsFragment on MarkdownRemarkFrontmatter {
    shopEquipmentUniversalProjects {
      title
      examples {
        photo {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        title
        description
      }
    }
  }
`;

export { ShopEquipmentUniversalProjects, shopEquipmentUniversalProjectsQuery };
