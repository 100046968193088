import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

const ShopEquipmentShoppingRoom = ({ background, alt, title, text, terms, shifts }) => {
  const features = [terms, shifts];
  return (
    <div className="ShopEquipmentShoppingRoom ">
      <Image className="ShopEquipmentShoppingRoom__image" image={background} alt={alt} />
      <div className="Content">
        <h2 className="ShopEquipmentShoppingRoom__title  [ Font_header sm:Font_smallHeader ]">{title}</h2>
        <div className="ShopEquipmentShoppingRoom__info">
          <div className="ShopEquipmentShoppingRoom_text [ sm:Font_regularText ]">{text}</div>
          <div className="ShopEquipmentShoppingRoom__features">
            {features.map(({ text, number, measure }) => (
              <div key={text} className="ShopEquipmentShoppingRoom__feature">
                <div className="ShopEquipmentShoppingRoom__feature_top">
                  <div className="ShopEquipmentShoppingRoom__featureNumber [ Color_black ]">{number}</div>
                  <div className="ShopEquipmentShoppingRoom__featureText [ Color_middleGrey ]">{text}</div>
                </div>
                <div className="ShopEquipmentShoppingRoom__featureMeasure  [ Color_black ] ">{measure}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

ShopEquipmentShoppingRoom.getLivePreviewData = ({ data, getAsset }) => ({
  shopEquipmentShoppingRoom: {
    ...data.shopEquipmentShoppingRoom,
    background: getAsset(data.shopEquipmentShoppingRoom.background).url,
  },
});

const shopEquipmentShoppingRoomQuery = graphql`
  fragment ShopEquipmentShoppingRoomFragment on MarkdownRemarkFrontmatter {
    shopEquipmentShoppingRoom {
      background {
        childImageSharp {
          fluid(maxWidth: 2240, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      alt
      title
      text
      terms {
        text
        number
        measure
      }
      shifts {
        text
        number
        measure
      }
    }
  }
`;

export { ShopEquipmentShoppingRoom, shopEquipmentShoppingRoomQuery };
